const removeActiveMapPoint = (activeMapItem: NodeList) => {
  [].forEach.call(activeMapItem, (el: HTMLElement) => {
    el.classList.remove("active");
  });
};

const removeActiveClass = (Elements: NodeList) => {
  [].forEach.call(Elements, (el: HTMLElement) => {
    if (el.classList.contains("active")) {
      el.classList.remove("active");
    }
  });
};

const setMapPoint = (mapLinkId: string) => {
  const newMapItem = document
    .querySelector(".map")
    ?.querySelectorAll("." + mapLinkId);
  [].forEach.call(newMapItem, (el: HTMLElement) => {
    el.classList.add("active");
  });
};

const toggleTab = (tabItem: HTMLAnchorElement, id?: string) => {
  const activeTab = <HTMLElement | null>document.querySelector(".tab.active");
  const allTabs = <NodeList>document.querySelectorAll(".tab");

  const allComanyItems = document.querySelectorAll(".company-item");

  const activeMapItem = document?.querySelectorAll(".map .active");

  // If clicked twice
  if (activeTab && activeTab.isSameNode(tabItem)) {
    tabItem.classList.remove("active");
    removeActiveMapPoint(activeMapItem);
    return;
  }

  removeActiveClass(allComanyItems);
  removeActiveClass(allTabs);
  activeMapItem && removeActiveMapPoint(activeMapItem);

  // Add Active to Tab or Slider item
  tabItem && tabItem.classList.add("active");
  id && setMapPoint(id);
};

const getIdByLocation = (hash: string) => {
  const mapLink = <HTMLElement>document.querySelector("[href='" + hash + "']");
  const tabItem = <HTMLAnchorElement | null>mapLink?.closest(".js-company");

  const id = hash?.replace("#", "");

  if (!tabItem) return;

  toggleTab(<HTMLAnchorElement>tabItem, id);
  setTimeout(() => {
    tabItem.scrollIntoView();
  }, 200);
};

const getIdByClick = (e: MouseEvent) => {
  const target = <HTMLElement | null>e.target;
  const mapLink = <HTMLAnchorElement | null>target?.closest(".map-link");
  const tabItem = <HTMLAnchorElement | null>target?.closest(".js-company");

  if (!mapLink || !tabItem || target?.classList.contains("detail-link")) {
    return;
  }

  const id = (
    mapLink.tagName.toLowerCase() === "button"
      ? mapLink?.getAttribute("data-href")
      : mapLink?.getAttribute("href")
  )?.replace("#", "");

  if (!id) {
    e.preventDefault();

    toggleTab(tabItem);

    return;
  }

  window.location.hash = id;

  toggleTab(tabItem, id);
};

export const handleMapPointsAndTabs = () => {
  document.addEventListener("click", (e) => {
    getIdByClick(e);
  });

  if (window.location.hash) {
    getIdByLocation(window.location.hash);
  }
};
