import "@fontsource/encode-sans/latin-400.css";
import "@fontsource/encode-sans/latin-700.css";
import "@fontsource/encode-sans/latin-800.css";
import "@fontsource/merriweather/latin-400.css";
import "../css/entry.css";
import { handleUsercentricsButtons } from "./components/handle-usercentrics-buttons";
import { initLightbox } from "./components/lightbox";
import { handleMapPointsAndTabs } from "./components/map-points-and-tabs";
import initNavbar from "./components/navbar";
import { handleScrollHeader } from "./components/scroll-header";
import { initShadows } from "./components/shadows";
import { initSlider } from "./components/slider";
import registerErrorReporting from "./error-reporting";

registerErrorReporting();

handleMapPointsAndTabs();
handleScrollHeader();
initNavbar();
initSlider();
initLightbox();
initShadows();
handleUsercentricsButtons();
