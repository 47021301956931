let lastScrollTopOffset = 0;

const fixHeader = (scrollOverWindowHeight: boolean) => {
  const homePageCheck: boolean =
    headerElement?.classList.contains("home-header");

  if (!homePageCheck) {
    headerElement.classList.add("header-fixed");
    return;
  }

  if (scrollOverWindowHeight) {
    headerElement?.classList.add("header-fixed");
  } else if (!scrollOverWindowHeight) {
    headerElement?.classList.remove("header-fixed");
  }
};

const collapseHeader = (scrollTopOffset: number) => {
  if ((scrollTopOffset || lastScrollTopOffset) < 0) {
    headerElement.classList.add("header-collapsed");
    return;
  }

  scrollTopOffset > lastScrollTopOffset
    ? headerElement?.classList.remove("header-collapsed")
    : headerElement?.classList.add("header-collapsed");
};

const handleHeader = () => {
  const navbar = <HTMLElement | null>document.querySelector(".nav");

  if (navbar?.classList.contains("active")) {
    return;
  }

  const scrollTopOffset: number =
    window.pageYOffset || document.documentElement.scrollTop;
  const scrollOverWindowHeight: boolean = scrollTopOffset >= 1;
  fixHeader(scrollOverWindowHeight);
  collapseHeader(scrollTopOffset);

  lastScrollTopOffset = scrollTopOffset;
};

const headerElement = <HTMLElement>document.querySelector(".js-main-header");

const events = ["resize", "scroll"];

export const handleScrollHeader = () => {
  for (let event of events) {
    window.addEventListener(event, handleHeader);
  }
};
