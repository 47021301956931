import Embla, { EmblaCarouselType, EmblaOptionsType } from "embla-carousel";

type CarouselType = "companies" | "logos" | "recipes" | "gallery";

const handlePagination = (carousel: EmblaCarouselType, type: CarouselType) => {
  document.addEventListener("click", (e) => {
    const target = <HTMLElement | null>e.target;

    if (!target || !target.closest(`.js-${type}-carousel`)) return;

    target.closest(".js-carousel-prev") && carousel.scrollPrev();

    target.closest(".js-carousel-next") && carousel.scrollNext();
  });
};

const handleContainerHeight = (carousel: EmblaCarouselType) => {
  const slideHeights = carousel.slideNodes().map((slideNode) => {
    const slideInner = slideNode.querySelector("*");
    return slideInner?.getBoundingClientRect().height;
  });

  const setContainerHeight = () => {
    const currentSlideHeight = slideHeights[carousel.selectedScrollSnap()];
    carousel.containerNode().style.height = `${currentSlideHeight}px`;
  };

  carousel.on("init", setContainerHeight);
  carousel.on("select", setContainerHeight);
};

const createCarousel = (type: CarouselType) => {
  const mainContainer = document.querySelector<HTMLElement>(
    `.js-${type}-carousel`
  );

  const mainElement = mainContainer?.querySelector<HTMLElement>(".embla");

  if (!mainElement) return;

  const options: Partial<EmblaOptionsType> = {
    align: "start",
    loop: true,
  };

  const isNotDraggable = type === "recipes" || type === "gallery";

  const carousel = Embla(
    mainElement,
    isNotDraggable ? { ...options, watchDrag: false } : options
  );

  type === "recipes" && handleContainerHeight(carousel);

  handlePagination(carousel, type);
};

export const initSlider = () => {
  const carouselNames: CarouselType[] = [
    "companies",
    "logos",
    "recipes",
    "gallery",
  ];
  carouselNames.forEach(createCarousel);
};
