/**
 * Navbar Mobile Button Handler
 */
const initNavbar = () => {
  const navbarTogglerEl = <HTMLElement | null>(
    document.querySelector(".js-navbar-toggler")
  );
  const headerNav = <HTMLElement | null>document.querySelector(".nav");

  navbarTogglerEl &&
    navbarTogglerEl.addEventListener("click", (): void => {
      headerNav?.classList.toggle("active");
      navbarTogglerEl?.classList.toggle("active");
    });
};

export default initNavbar;
