export const initShadows = () => {
  const checkIfModuleBackgroundBrown = (moduleElement: HTMLElement) => {
    if (moduleElement == undefined || moduleElement == null) {
      return false;
    }

    return moduleElement.classList.contains("bg-brown");
  };

  const modules = document?.querySelectorAll(".page-module");
  const firstModule = <HTMLElement>modules[0];
  const lastModule = <HTMLElement>modules[modules.length - 1];
  const header = <HTMLElement | null>document.querySelector(".js-main-header");
  const footer = <HTMLElement | null>document.querySelector("footer");

  if (checkIfModuleBackgroundBrown(firstModule)) {
    header?.classList.add("header-shadow");
  }

  if (checkIfModuleBackgroundBrown(lastModule)) {
    footer?.classList.add("footer-shadow");
  }
};
