interface WindowWithUsercentrics extends Window {
  UC_UI?: {
    showFirstLayer: () => void;
    showSecondLayer: () => void;
  };
}

export const handleUsercentricsButtons = () => {
  document
    .querySelector('[href="#show-usercentrics-modal"]')
    ?.addEventListener("click", (event) => {
      event.preventDefault();
      (<WindowWithUsercentrics>(<unknown>window)).UC_UI?.showSecondLayer();
    });
};
