import GLightbox from "glightbox";

const addLightbox = (lightbox: NodeList) => {
  if (!lightbox) {
    return;
  }

  [].forEach.call(lightbox, (el: HTMLElement) => {
    const elClass = el.querySelector("a")?.classList[0];

    GLightbox({
      selector: "." + elClass,
      touchNavigation: true,
      touchFollowAxis: true,
      loop: true,
    });
  });
};

const companyLightbox = document.querySelectorAll(".tab .title-img");
const accommodationLightboxElement =
  document.querySelectorAll(".accommodation-img");
const galleryLightboxElement = document.querySelectorAll(".js-gallery-item");

export const initLightbox = () => {
  addLightbox(companyLightbox);
  addLightbox(accommodationLightboxElement);
  addLightbox(galleryLightboxElement);
};
